import React from 'react';

const RestaurantPrivacy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">Restaurant Privacy Policy</h1>
      <p className="text-2xl">
        <strong>Introduction:</strong> We value your privacy and are committed to protecting your personal information. 
        This app, including all its services and operations, is provided under SAVORSYNTH FOODTECH PRIVATE LIMITED.
      </p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">Information Collection</h2>
      <ul className="list-disc list-inside text-2xl">
        <li><strong>Business Information:</strong> We collect business information such as name, contact details, and payment information.</li>
        <li><strong>Usage Information:</strong> We collect information about your use of the app, including order history and preferences.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Use of Information</h2>
      <p className="text-2xl">We use your information to provide and improve our services, process orders, and communicate with you.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Sharing of Information</h2>
      <p className="text-2xl">We do not share your personal information with third parties except as required by law or to provide our services.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Data Security</h2>
      <p className="text-2xl">We implement security measures to protect your information from unauthorized access or disclosure.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Data Retention</h2>
      <p className="text-2xl">We retain your information as long as necessary to fulfill the purposes outlined in this policy.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">User Rights</h2>
      <p className="text-2xl">You have the right to access, modify, or delete your personal information. Contact us to exercise these rights.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Changes to Policy</h2>
      <p className="text-2xl">We may update this policy from time to time. We will notify you of significant changes through the app.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Contact Information</h2>
      <p className="text-2xl">For any queries, contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>.</p>
      
      <footer className="text-2xl mt-10">
        <p className="text-center">© {new Date().getFullYear()} SAVORSYNTH FOODTECH PRIVATE LIMITED. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default RestaurantPrivacy;
