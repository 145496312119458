import React from 'react';

const DeliveryPartnerPrivacy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">Privacy Policy for ZipZap, Inc.</h1>
      <p className="text-2xl"><strong>Effective Date:</strong> 23-07-2024</p>
      <p className="text-2xl"><strong>Introduction:</strong> We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and share your information when you use our app.</p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">1. Information Collection</h2>
      <ul className="list-disc list-inside text-2xl">
        <li><strong>Personal Information:</strong> We collect personal information such as your name, contact details, and payment information.</li>
        <li><strong>Usage Information:</strong> We collect information about your use of the app, including delivery history and preferences.</li>
        <li><strong>Phone Number Detection:</strong> To enhance your user experience, our app may automatically detect your phone number using the <code>android.permission.READ_PHONE_STATE</code> permission, streamlining sign-up and login processes.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">2. Use of Information</h2>
      <p className="text-2xl">We use your information to provide and improve our services, process deliveries, and communicate with you.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">3. Sharing of Information</h2>
      <p className="text-2xl">We do not share your personal information with third parties, except as required by law or to provide our services. We may share aggregated or anonymized data that does not identify you.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">4. Data Security</h2>
      <p className="text-2xl">We implement security measures to protect your information from unauthorized access, disclosure, or misuse.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">5. Data Retention</h2>
      <p className="text-2xl">We retain your information only as long as necessary to fulfill the purposes outlined in this policy and comply with legal obligations.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">6. User Rights</h2>
      <p className="text-2xl">You have the right to access, modify, or delete your personal information. To exercise these rights, please contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">7. Changes to This Policy</h2>
      <p className="text-2xl">We may update this policy from time to time. We will notify you of significant changes through the app.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">8. Contact Information</h2>
      <p className="text-2xl">For any inquiries regarding this Privacy Policy or your personal information, please contact us at: <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>.</p>
    </div>
  );
};

export default DeliveryPartnerPrivacy;