import React from "react";
import PropTypes from "prop-types";

const Footer = ({ logo }) => (
  <footer className="footer">
    <div className="container mx-auto grid grid-cols-5 gap-12">
      <div className="logo-col">
        <a href="/" alt="logo" className="footer-logo">
          <img src={logo} alt="ZipZap logo" className="logo1" />
        </a>
        <ul className="social-links">
          <li>
            <a
              href="https://www.instagram.com/zipzap.in/"
              className="footer-link"
            >
              <ion-icon className="social-icon" name="logo-instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/zipzap-foods"
              className="footer-link"
            >
              <ion-icon className="social-icon" name="logo-linkedin" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/zipzaplive" className="footer-link">
              <ion-icon className="social-icon" name="logo-twitter" />
            </a>
          </li>
        </ul>
        <p className="copyright">
          &copy; 2024 by ZipZap, Inc. All rights reserved.
        </p>
      </div>
      <div className="address-col">
        <p className="footer-heading">Contact us</p>
        <address className="contacts">
          <p className="address">Bangalore, India</p>
          <p>
            <a href="tel:6383522927" className="footer-link block">
              6383522927
            </a>
            <a
              href="mailto:hello@ZipZap.com"
              className="footer-link block"
            >
              sundaranbu@zipzap.live
            </a>
          </p>
        </address>
      </div>
      <nav className="nav-col">
        <p className="footer-heading">Account</p>
        <ul className="footer-nav">
          <li>
            <a href="/" className="footer-link">
              Create account
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Sign in
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              iOS app
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Android app
            </a>
          </li>
        </ul>
      </nav>
      <nav className="nav-col">
        <p className="footer-heading">Company</p>
        <ul className="footer-nav">
          <li>
            <a href="/" className="footer-link">
              About ZipZap
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              For Business
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Cooking partners
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Careers
            </a>
          </li>
        </ul>
      </nav>
      <nav className="nav-col">
        <p className="footer-heading">Resources</p>
        <ul className="footer-nav">
          <li>
            <a href="/" className="footer-link">
              Recipe directory
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Help center
            </a>
          </li>
          <li>
            <a href="/" className="footer-link">
              Privacy & terms
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
);

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Footer;
